import React from "react";

//import Scanner from "../Scanner/Scanner";
import SiteCategories from "./SiteCategories";
import MemberList from "../MemberList/MemberHeader";
import SiteInvites from "./SiteInvites";
import Bags from "./SiteBags";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { ToastContainer, Zoom } from "react-toastify";

const SiteSettingsHeader = () => {
  //Photos
  //https://www.cleanpng.com/free/id.html

  //var bottomBar = document.getElementById("popup-1");

  return (
    <>
      <div className="topbarEmptySpace"></div>

      <div className="detailsPopupContainer">
        <Tabs>
          <TabList>
     
            <Tab>
              <b>Categories</b>
            </Tab>
            <Tab>
              <b>Member List</b>
            </Tab>
            <Tab>
              <b>Invites</b>
            </Tab>
          </TabList>

   
          <TabPanel>
            <SiteCategories />
          </TabPanel>
          <TabPanel>
            <MemberList />
          </TabPanel>
          <TabPanel>
            <SiteInvites />
          </TabPanel>
        </Tabs>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        theme="light"
        transition={Zoom}
      />

    </>
  );
};

export default SiteSettingsHeader;
